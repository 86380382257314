<template>
  <v-card class="ma-4 cardBoxShadow rounded-lg">
    <div class="px-4 pt-4 w-100">
      <v-form
          class="d-flex align-center"
          v-model="formValid"
          ref="form"
          lazy-validation
          @submit.prevent="getSuspenseLogs()"
        >
          <div class="pa-4">
            <div>* Date</div>
            <v-menu
              v-model="dateMenu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  @click:append="dateMenu1 = true"
                  class="apiKeyInput"
                  v-model="computedDateFormatted1"
                  outlined
                  persistent-hint
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="fromDateRules"
                  placeholder="Select the From Date"
                  append-icon="mdi-calendar"
                  readonly
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                :show-current="true"
                no-title
                @input="dateMenu1 = false"
                :max="new Date().toISOString().substr(0, 10)"
              >
              </v-date-picker>
            </v-menu>
          </div>
  
          <div class="px-2 d-flex justify-end mb-2 mt-2">
            <v-btn
              type="submit"
              height="40"
              color="primary"
              depressed
              :loading="loader"
              class="text-capitalize fsize14"
              >Submit</v-btn
            >
          </div>
        </v-form>

      <v-data-table
        v-if="suspenseLogs.length != 0"
        :headers="headers"
        v-bind:items="suspenseLogs"
        :search="search"
        :value="suspenseLogs"
        class="my-2"
        id="table"
        item-key="name"
        single-select
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-left">{{ props.index + 1 }}</td>
            <td class="text-center">
              {{
                props.item?.transactionDate?.split(" ")[0]
                  ? props.item?.transactionDate?.split(" ")[0]
                  : "-"
              }}
            </td>
            <td class="text-left">{{ props.item.remitterName }}</td>
            <td class="text-right">{{ props.item.remitterAccount }}</td>
            <td class="text-right">{{ props.item.remitterBank }}</td>
            <td class="text-right">
              {{
                props.item.amount ? Number(props.item.amount).toFixed(2) : ""
              }}
            </td>
            <td class="text-center">{{ props.item.mnemonicCode }}</td>

            <td class="d-flex justify-center align-center">
              <v-btn
                v-if="!props.item.isAllocate"
                @click="props.item.isAllocate = true"
                height="24"
                width="80"
                depressed
                :color="'#808080'"
                class="text-capitalize fsize12 white--text ml-2"
              >
                <!-- {{ i.icclStatus == "1" ? "Success" : "Failed" }} -->
                Allocate
              </v-btn>

              <input
                v-if="props.item.isAllocate"
                v-model="props.item.allocateUserId"
                class="tableUserInput py-2"
                placeholder="User Id"
              />
              <div class="ml-2 min-w-80">
                <v-btn
                  v-if="
                    props.item.isAllocate && props.item.allocateUserId != ''
                  "
                  height="24"
                  @click="
                    handleUpdateClick(props.item),
                      (props.item.isAllocate = false)
                  "
                  width="80"
                  depressed
                  :color="'#808080'"
                  class="text-capitalize fsize12 white--text"
                >
                  Update
                </v-btn>
                <v-btn
                  v-if="
                    props.item.isAllocate && props.item.allocateUserId == ''
                  "
                  height="24"
                  @click="
                    (props.item.isAllocate = false)
                  "
                  width="80"
                  depressed
                  :color="'#808080'"
                  class="text-capitalize fsize12 white--text"
                >
                  Cancel
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div
        v-else-if="!loader"
        class="d-flex align-center justify-center min-height-100"
      >
        No Records Found
      </div>
    </div>
  </v-card>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import commonFunctions from '../../mixins/commonFunctions'
export default {
  data: () => ({
    formValid: true,
    dateMenu1: false,
    fromDate: null,
    headers: [
      // { text: "Date", align: "center", value: "date" },
      { text: "S.No", align: "left", value: "" ,sortable: false},
      { text: "Date", align: "center", value: "transactionDate",sortable: true,},
      { text: "Name", align: "left", value: "remitterName",sortable: true},
      { text: "Account Number", align: "right", value: "remitterAccount",sortable: true},
      { text: "Bank Name", align: "right", value: "remitterBank",sortable: true},
      { text: "Amount", align: "right", value: "amount",sortable: true},
      { text: "Status", align: "center", value: "mnemonicCode",sortable: true},
      { text: "Allocate", align: "center", value: "",sortable: false},
      // Add other headers if needed
    ],
    fromDateRules: [(v) => !!v || "From Date is required"],
    search: "",
  }),
  mixins:[commonFunctions],
  methods: {
    getSuspenseLogs() {
      let json = {
        date: this.fromDate
      }
      this.$store.dispatch("payments/getSuspenseLogs", json);
    },
    handleUpdateClick(value) {
      let json = {
        userId: value.allocateUserId,
        amount: value.amount,
        remitterName: value.remitterName,
        alertSequenceNo: value.alertSequenceNo,
        remitterAcc: value.remitterAccount,
        remitterIfsc: "",
        chequeNo: "",
        userRefNum: "",
        transactionDescription: "",
        transactionDate: "",
        fromSuspense: "true",
      };

      this.$store.dispatch("payments/allocateFailedHdfcLogs", {
        json: json,
        from: "suspense",
      });
    }
  },

  computed: {
    ...mapGetters({
      loader: "getLoader",
    }),
    ...mapState("payments", ["suspenseLogs"]),
    computedDateFormatted1: {
      get() {
        return this.VutifyFormatDate(this.fromDate,'-');
      },
      set() {},
    },
  },
  created() {
    this.fromDate = new Date().toISOString().substr(0, 10);
    this.getSuspenseLogs()
  },
};
</script>
<style scoped>
.min-w-80 {
  min-width: 80px;
}
</style>
