<template>
  <div>
    <v-form
      v-if="isOrderPushRequest && paymentData"
      v-model="formValid"
      ref="msgForm"
      lazy-validation
      @submit.prevent="sendOrderPushRequest()"
    >
      <v-row no-gutters class="px-4 py-10">
        <v-col cols="12" sm="12" md="6" lg="3">
          <div class="fsize14 font-weight-bold primaryColor pb-4">
            Order Push Request
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="5">
          <div>
            <div class="primaryColor fsize14 pb-1">User ID</div>
            <v-text-field
              dense
              @input="
                payment_data.userId
                  ? (payment_data.userId = payment_data.userId.toUpperCase())
                  : null
              "
              v-model="payment_data.userId"
              outlined
              readonly
              class="bg-readonly"
            >
            </v-text-field>
          </div>

          <div class="primaryColor fsize14 pb-1">Amount</div>
          <v-text-field
            dense
            @input="amount ? (amount = amount.toUpperCase()) : null"
            v-model="amount"
            @keypress="keyPressNumeric($event)"
            placeholder="Enter Amount"
            outlined
            :rules="amountRules"
          >
          </v-text-field>
          <div class="d-flex w-100 gap-5">
            <div class="w-100">
              <div class="primaryColor fsize14 pb-1">Segment</div>
              <v-select
                outlined
                dense
                height="40"
                v-model="segment"
                :items="items"
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </div>
            <div class="w-100">
              <div class="primaryColor fsize14 pb-1">IFSC Code</div>
              <v-text-field
                dense
                @input="
                  selectedIfsc
                    ? (selectedIfsc = selectedIfsc.toUpperCase())
                    : null
                "
                v-model="selectedIfsc"
                outlined
                readonly
                class="bg-readonly"
              >
              </v-text-field>
            </div>
          </div>
          <div class="d-flex w-100 gap-5">
            <div class="w-100">
              <div class="primaryColor fsize14 pb-1">Bank Account Number</div>
              <v-select
                outlined
                dense
                height="40"
                v-model="selectedBankAcc"
                :items="bankAccountNumberArray"
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </div>
            <div class="w-100">
              <div class="primaryColor fsize14 pb-1">Bank Name</div>
              <v-text-field
                dense
                @input="
                  selectedBankName
                    ? (selectedBankName = selectedBankName.toUpperCase())
                    : null
                "
                v-model="selectedBankName"
                outlined
                class="bg-readonly"
                readonly
              >
              </v-text-field>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="4"> </v-col>
      </v-row>
      <div class="px-4 d-flex gap-5 justify-end pb-4">
        <v-btn
          @click.prevent="backToManualPayments()"
          depressed
          outlined
          height="40"
          width="100"
          color="black"
          type="button"
          class="text-capitalize rounded-sm-md cancelbtn ml-2"
          >Cancel</v-btn
        >
        <v-btn
          type="submit"
          :loading="getLoader"
          :disabled="getLoader"
          height="40"
          width="100"
          color="primary"
          depressed
          class="text-capitalize fsize14 ml-2"
          >Submit</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "orderPushRequest",

  computed: {
    ...mapState("payments", [
      "isOrderPushRequest",
      "paymentData",
      "bankAccountNumberArray",
      "bankDetailsArray",
    ]),
    payment_data: {
      get() {
        this.selectedBankAcc = this.paymentData.bankActNo;
        this.selectedBankName = this.paymentData.bankName;
        this.selectedIfsc = this.paymentData.ifscCode;
        return this.paymentData;
      },
    },
  },
  data: () => ({
    formValid: true,
    userIdRules: [(v) => !!v || "User ID is required"],
    selectedBankAcc: "",
    userId: "",
    amount: "",

    segment: "NSE_FNO",
    items: [
      "NSE_FNO",
      "NSE_CASH",
      "BSE_CASH",
      "BSE_FNO",
      "MCX",
      "CD_NSE",
      "CD_BSE",
    ],
    getLoader: false,
    selectedIfsc: "",
    selectedBankName: "",
    amountRules: [(v) => !!v || "Amount is required"],
    bankNameRules: [(v) => !!v || "Bank Name is required"],
  }),
  mounted() {},
  watch: {
    selectedBankAcc(value) {
      this.bankDetailsArray.map((el) => {
        if (el.bankActNo == value) {
          this.selectedIfsc = el.ifscCode;
          this.selectedBankName = el.bankName;
        }
      });
    },
  },
  beforeCreate() {},
  methods: {
    sendOrderPushRequest() {
      let json = {
        bankName: this.selectedBankName,
        bankActNo: this.selectedBankAcc,
        segment: this.segment,
        ifscCode: this.selectedIfsc,
        amount: this.amount,
        userId: this.payment_data.userId,
      };
      this.$store
        .dispatch("payments/sendOrderPushRequest", json)
        .finally(() => {
          this.backToManualPayments();
        });
    },
    backToManualPayments() {
      this.$store.commit("payments/setOrderPushRequest", false);
    },
    keyPressNumeric(event) {
      var inp = String.fromCharCode(event.keyCode);

      if (event.keyCode != 13) {
        if (/^-?\d+(\.\d+)?$/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },
  },
};
</script>
<style>
.gap-5 {
  gap: 20px !important;
}
.bg-readonly .v-input__slot {
  background-color: rgb(215, 211, 211) !important;
}
</style>
